import Vue from 'vue'
import App from './App.vue'
import "./registerServiceWorker";
import router from './router/router'
import store from './store/index'
import { i18n } from './i18n';
import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './stylessheets/style.scss';

import Multiselect from 'vue-multiselect'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import pt from 'vee-validate/dist/locale/pt_BR.json';
import VueBingMaps from './components/shared/vue-bing-map'
import Emitter from 'tiny-emitter';
import customTokenCredential from './CustomTokenProvider';
import { BlobServiceClient } from '@azure/storage-blob';
import { PublicClientApplication } from '@azure/msal-browser';
import { mapMutations } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { Icon } from '@iconify/vue2';

import IdleVue from 'idle-vue'
import { SESSION_EXPIRATION, BOARD_ALIAS, EKEY } from './app_constants'
import { PERMISSIONS } from './domain_constants';
import VueCryptojs from 'vue-cryptojs'

import moment from 'moment';
import VueMoment from 'vue-moment';

import vClickOutside from 'v-click-outside'
import './registerServiceWorker'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)


// Load Locales ('en' comes loaded by default)
require('moment/locale/es');

// Choose Locale
moment.locale('es');

Vue.use(VueMoment, { moment });

Vue.use(vClickOutside)

Vue.use(VueCryptojs)

//* TOAST OPTIONS
const options = {
  position: "bottom-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

let timeoutId
Vue.use(IdleVue, { eventEmitter: new Vue(), idleTime: parseInt(SESSION_EXPIRATION.IDLE_TIME), store: store }) //15 seg

Vue.use(Toast, options);

Vue.component('icon', Icon)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO('https://fraktal.wes.com.pe/'), 
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
}));
Vue.config.productionTip = false


Vue.component('multiselect', Multiselect)
Vue.use(BootstrapVue)
Vue.prototype.$msalInstance = {};
Vue.prototype.$emitter = new Emitter();
console.log('Calling Vue.use', VueBingMaps);
Vue.use(VueBingMaps, { debug: true });

window.Vue = Vue;

localize({
  en,
  es,
  fr,
  pt
});

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule]// copies rule configuration
  });
});

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: i18n.t('message.url'),
})

Vue.mixin({
  async created() {
    try {
      this.$msalInstance = new PublicClientApplication(
        this.$store?.state?.msalConfig,
      );
      this.$i18n.locale = this.$store?.getters?.getLocale || 'es'
    } catch (error) {
      console.error(error);
    }
    
    window.addEventListener('offline', this.onOffline)
    window.addEventListener('online', this.onOnline)
    
  },
  data() {
    return {
      exitt: true,
      isConnected: false,
      currentMenu: null,
      isOnline: navigator.onLine,
    }
  },

  computed: {
    msAccount() {
      return this.$store?.getters.msAccount
    },
    project() {
      let pj = this.$store?.getters.currentProject
      if (pj == null)
        return { 'id': null, 'logo': require('@/assets/icon2.png'), 'name': 'Fraktal', 'color': '#333399' }
      else
        return pj
    },
    policyStatus() {
      return this.$store?.getters.getPolicyStatus
    },
    currentUser() {
      return this.$store?.getters.getCurrentUser
    },
    permissions() {
      return this.$store?.getters.getPermissionProjects
    },
    isAdminUser() {
      return this.currentUser?.admin || this.permissions?.includes(PERMISSIONS.ADMIN)
    },
    isSystemAdminUser(){
      return this.currentUser?.admin
    },
    isLoading() {
      return this.$store?.getters.isLoading > 0;
    },
    menu() {
      return this.$store?.getters.getMenu;
    },
  },
  mounted() {
    for (let i = 0; i < this.menu?.length; i++) {
      this.currentMenu = this.dfs(this.menu[i], this.$route);
      if (this.currentMenu != null) break
    }
    if (this.$store?.getters?.getLocale == null)
      this.$store?.dispatch('setLocale', this.getBrowserLocales({ languageCodeOnly: true })?.[0] || 'es')
  },
  watch: {
    '$route': function (to, from) {
      if (this.menu == null) return
      for (const element of this.menu) {
        this.currentMenu = this.dfs(element, to);
        if (this.currentMenu != null) break
      }
    },
  },
  destroyed() {
    window.removeEventListener('offline', this.onOffline)
    window.removeEventListener('online', this.onOnline)
  },
  methods: {
    getBrowserLocales(options = {}) {

      const defaultOptions = {

        languageCodeOnly: false,

      };

      const opt = {

        ...defaultOptions,

        ...options,

      };

      const browserLocales =

        navigator.languages === undefined

          ? [navigator.language]

          : navigator.languages;

      if (!browserLocales) {

        return undefined;

      }

      return browserLocales.map(locale => {

        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly

          ? trimmedLocale.split(/-|_/)[0]

          : trimmedLocale;

      });

    },
    onOffline() { this.online = false },
    onOnline() { this.online = true },
    async SignIn() {
      await this.$msalInstance.handleRedirectPromise();
      await this.$msalInstance
        .loginPopup({
          prompt: "login" // "select_account" will force account selection
        })
        .then(() => {

          const myAccounts = this.$msalInstance.getAllAccounts();
          let account = myAccounts[0];
          this.$store.dispatch('setMsAccount', account);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      const currentAccount = this.$msalInstance.getAccountByHomeId(this.msAccount.homeAccountId);
      await this.$msalInstance.handleRedirectPromise();
      await this.$msalInstance
        .logoutPopup({
          account: currentAccount,
          mainWindowRedirectUri: 'https://fraktalwes.com/'
        })
        .then(() => {
          this.$store.dispatch('setMsAccount', null);
          this.$store.dispatch('logOut', { redirect: true })
        })
        .catch(error => {
          console.error(error);
        });
    },
    pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
      let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      let uicolors = [r / 255, g / 255, b / 255];
      let c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
      return (L > 0.999) ? darkColor : lightColor;
    },
    dfs(node, target) {
      if (node.alias === target.name && node.alias != null && node.report == null) {
        return node;
      }
      else if (node.alias === target.name && node.alias != null && node.report != null) {
        if (node.alias == BOARD_ALIAS && node.report == this.$CryptoJS.AES.decrypt(target.params.urir, EKEY).toString(this.$CryptoJS.enc.Utf8) && target.params.urir != undefined)
          return node;
      }
      if (node.submenu) {
        for (let i = 0; i < node.submenu.length; i++) {
          const result = this.dfs(node.submenu[i], target);
          if (result) {
            return result;
          }
        }
      }

      return null;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
})
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
});

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});
new Vue({
  router,
  store,
  i18n,
  onIdle() {
    if (store.getters.isAuthenticated && store.getters.isLoading == 0) {
      timeoutId = setTimeout(() => { store.dispatch("logOut", 'idle') }, SESSION_EXPIRATION.TIMEOUT)
    }
  },
  onActive() {
    clearTimeout(timeoutId)
  },
  render: h => h(App)
}).$mount('#app')
